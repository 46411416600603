:root {
  --radius: 30px;
}

.select-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  height: 50px;
}

.select-wrapper::after {
  content: "";
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13.0px 7.5px 0 7.5px;
  border-color: var(--technolab-blue) transparent transparent transparent;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  border: none;
  padding: 10px 50px 10px 30px;
  margin: 0;
  background-color: var(--tile-background);
  outline: none;
  color: var(--technolab-blue);
}

.select:hover {
  cursor: pointer;
}

.select:focus {
  outline: none;
  box-shadow: 0px 0px 10px 1px rgba(9, 153, 215, .2);
  background-color: var(--scrollbar-color);
}

.select__list-container {
  position: absolute;
  left: var(--radius);
  z-index: 10;
  background-color: var(--scrollbar-color);
  width: calc(100% - 2 * var(--radius));
  padding: 5px 2px 5px 20px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  min-height: 200px;
  max-height: 50vh;
  height: max-content;
}

.select__list-item {
  background: none;
  border: none;
  color: var(--technolab-blue);
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

.select__list-item:hover {
  cursor: pointer;
  background-color: var(--technolab-blue);
  color: var(--scrollbar-color);
}

.select__list-item_active {
  background-color: var(--technolab-blue);
  color: var(--scrollbar-color);
}