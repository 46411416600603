.link-button {
  padding: 10px 40px;
  border-radius: 22px;
  text-decoration: none;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--tile-background);
  color: var(--technolab-blue);
  transition: all .3s linear;
  font-size: 20px;
}

.link-button_color_blue {
  background-color: var(--technolab-blue);
  color: #fff;
}

.link-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}


@media (max-width: 1100px) {
  .link-button {
    font-size: 16px;
  }
}

@media (max-width: 550px) {
  .link-button {
    font-size: 12px;
    padding: 10px 15px;
    height: 35px;
  }
}