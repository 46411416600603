.about {
  display: grid;
  grid-template-areas: 'main main'
    'project provider'
    'project competition'
    'distributor competition';
  grid-template-rows: 200px auto auto auto;
  grid-template-columns: .8fr 1fr;
  gap: 20px;
  box-sizing: border-box;
  width: 75%;
  min-height: 60vh;
}

.about__tile {
  position: relative;
  z-index: 5;
  justify-content: start;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.about__tile_type_main {
  grid-area: main;
}

.about__tile_type_provider {
  grid-area: provider;
  background-color: rgba(0, 118, 189, .8);
  background-image: url(../../images/about/provider-background.png);
  background-size: 100% auto;
  background-position: 220px -50px;
}

.about__tile_type_project {
  grid-area: project;
  background-color: var(--research-color);
}

.about__tile_type_project-objective {
  background-image: url(../../images/about/project-objective-background.png);
  background-position: right top;
  background-size: 40% auto;
  margin-top: 15px;
}

.about__tile_type_competition {
  grid-area: competition;
  background-image: url(../../images/about/competition-background.png);
  background-size: 80% auto;
}

.about__tile_type_distributor {
  grid-area: distributor;
  background-color: var(--technolab-blue);
  background-image: url(../../images/about/distributor-background.png);
  background-size: 60% auto;
}

.about__title {
  margin-bottom: 10px;
  color: #fff;
}

.about__title_type_competition {
  color: var(--technolab-blue);
}

.about__title_type_project-objective {
  color: var(--research-color);
}

.about__text {
  color: #ffffffbd;
  font-size: 18px;
}

.about__text_type_main {
  justify-content: center;
  width: 50%;
}

.about__text_type_competition {
  color: var(--dark-grey);
}


.about__text_type_project-objective {
  color: var(--dark-grey);
}

@media (max-width: 1400px) {
  .about__text_type_main {
    font-size: 18px;
  }
}

@media (max-width: 1350px) {
  .about__text_type_main {
    width: 45%;
  }
}

@media (max-width: 1200px) {

  .about__tile_type_competition {
    background-size: auto 50%;
  }
}

@media (max-width: 950px) {
  .about {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .about__text_type_main {
    width: 100%;
    font-size: 18px;
  }

  .about {
    width: 90%;
    display: flex;
    flex-direction: column;
  }


  .about__tile_type_competition {
    background-image: none;
  }
}