.file-link {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.file-link__title {
  grid-area: title;
  margin: 0;
  padding: 0 0 20px 0;
  transition: all .3s linear;
}

.file-link__about {
  text-align: justify;
  margin: 0;
  padding: 0 0 15px 0;
  font-size: 18px;
  text-transform: initial;
  grid-area: text;
}

.file-link__call {
  grid-area: call;
  margin: 0;
  padding: 0;
  font-size: 16px;
  opacity: .8;
  transition: all .3s linear;
  text-transform: uppercase;
  text-align: right;
  align-self: flex-end;
}

.file-link__tile:hover .file-link__call {
  color: #099ad7;
}

@media (max-width: 830px) {

  .file-link__title {
    font-size: 16px;
  }

  .file-link__about {
    font-size: 16px;
  }

  .file-link__call {
    font-size: 15px;
  }

  .file-link__container {
    width: 100%;
  }
}

@media (max-width: 700px) {

  .file-link__container {
    grid-template-rows: 1fr 1fr 0;
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 550px) {

  .file-link__call {
    font-size: 12px;
  }

  .file-link__about {
    font-size: 14px;
  }

  .file-link__title {
    font-size: 14px;
    padding-bottom: 10px;
  }
}