.contacts {
  width: 75%;
  display: grid;
  grid-template-areas: 'main-text main-text phone phone'
    'support order order order'
    'support order order order'
    'support address address address';
  grid-template-columns: 40% auto 20% 10%;
  gap: 20px;
  box-sizing: border-box;
  z-index: 5;
}

.contacts__tile {
  display: flex;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: right bottom;
  color: #fff;
}

.contacts__tile_type_main {
  grid-area: main-text;
  background-color: rgba(9, 153, 215, .8);
  background-image: url(../../images/contacts/main-background.png);
}

.contacts__main-text {
  font-weight: 200;
}

.contacts__tile_type_address {
  grid-area: address;
  background-color: rgba(216, 177, 60, .8);
  background-image: url(../../images/contacts/address-background.png);
  color: #3d3d3d;
  background-position: right 300%;
}

.contacts__tile_type_support {
  grid-area: support;
  background-color: rgba(9, 153, 215, .8);
  background-image: url(../../images/contacts/support-background.png);
  background-position: left center;
  background-size: 55% auto;
}

.contacts__tile_type_order {
  grid-area: order;
  color: var(--technolab-blue);
  color: var(--grey);
  background-image: url(../../images/contacts/order-background.png);
  background-size: 50% auto;
  background-position: right center;
}

.contacts__tile_type_phone {
  grid-area: phone;
  background-color: rgba(168, 91, 159, .8);
  background-image: url(../../images/contacts/phone-background.png);
  background-position: -30% 150%;
  background-size: auto 60%;
}

@media (max-width:900px) {
  .contacts {
    width: 90%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .contacts__tile_type_address {
    background-position: right 400%;
  }
}

@media (max-width:450px) {
  .contacts__tile {
    background-image: none;
  }
}