.card-with-text {
  background-color: var(--tile-background);
  height: 550px;
  width: 60vw;
  max-width: 600px;
  border-radius: 30px;
  overflow: hidden;
  padding: 5px 0 25px 20px;
  position: relative;
  z-index: 3;
}

@media (max-width: 1200px) {

  .card-with-text {
    width: 45vw;
  }
}

@media (max-width: 900px) {

  .card-with-text {
    width: 80vw;
    margin: 0 auto;
  }
}

@media (max-width: 500px) {

  .card-with-text {
    background-color: rgba(241, 240, 240, .9);
    height: 400px;
  }
}