.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, .6);
  transition: background-color .3s linear;
}

.popup_hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .1);
}

.popup__container {
  width: 50%;
  height: 70%;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: var(--header-background);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 79.5px;
}

@media(max-width:1100px) {
  .popup__container {
    width: 70%;
  }
}

@media(max-width:500px) {
  .popup__container {
    height: auto;
  }
}