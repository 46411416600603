.double-tile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  margin: 0;
}

.double-tile__item {
  font-size: 18px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.double-tile__item_type_promo {
  margin-right: 40px;
  transition: transform .3s linear;
  width: 50%;
}

.double-tile__item_type_img {
  width: 20%;
  height: 100%;
  padding: 0;
  background: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.double-tile__item_type_about {
  margin: 0;
  width: 100%;
}

.double-tile__about {
  height: 70%;
  text-transform: none;
  font-size: 20px;
  font-weight: normal;
  width: 100%;
}

.double-tile__links-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 20px;
  overflow: hidden;
}

.double-tile__links-container *:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 900px) {

  .double-tile__item_type_promo {
    margin-right: 20px;
  }

  .double-tile__about {
    height: 75%;
  }

  .double-tile__item_type_img {
    width: 30%;
    height: auto;
  }

  .double-tile__links-container {
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }

  .double-tile__links-container *:not(:last-child) {
    margin: 0 0 10px 0;
  }

}


@media (max-width: 550px) {

  .double-tile {
    flex-direction: column;
    height: auto;
    margin: 0;
  }

  .double-tile__item_type_promo {
    margin: 0;
    width: 100%;
  }

  .double-tile__item_type_img {
    margin: 0;
    align-self: center;
    width: 50%;
  }

  .double-tile__item_type_about {
    margin-top: 10px;
  }

}