.up-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: #fff;
  color: var(--technolab-blue);
  border: none;
  z-index: 20;
  opacity: .3;
  transition: all .3s linear;
}

.up-button:hover {
  cursor: pointer;
  opacity: .6;
}