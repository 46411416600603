.slider {
  width: 280px;
  height: 240px;
  -ms-grid-column-align: end;
  justify-self: end;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 10px 40px 10px;
}

.slider_big {
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
  border-radius: 30px;
}

.slider__img {
  max-height: calc(100% - 30px);
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  transition: all .3s linear;
}

.slider__img_button:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.slider__buttons-container {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.slider__state-bar {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.slider__state-item {
  background-color: none;
  border: 1px solid var(--grey);
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 5px;
}

.slider__state-item_active {
  background-color: var(--grey);
  transform: scale(1.3);
}

.slider__btn {
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 100%;
  background: none;
  color: var(--grey);
  font-weight: bold;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s linear;
}

.slider__btn:hover {
  cursor: pointer;
  transform: scale(1.3);
}

.slider__btn_type_left {
  margin-right: 30px;
}

.slider__btn_type_right {
  margin-left: 30px;
}

.slider__big-btn {
  transition: all .3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  position: absolute;
  height: 100%;
  width: 40px;
  background-color: rgba(187, 187, 187, 0);
  border: none;
  padding: 0 50px;
  z-index: 5;
}

.slider__big-btn:hover {
  cursor: pointer;
  background-color: rgba(187, 187, 187, .6);
}

.slider__big-btn_type_left {
  left: 0;
  top: 0;
}

.slider__big-btn_type_right {
  right: 0;
  top: 0;
}

.slider_big .slider__btn {
  display: none;
}

@media (max-width: 500px) {
  .slider {
    width: 180px;
    height: auto;
    margin: 0 10px 10px 0;
  }

  .slider_big {
    width: 100%;
    height: 100%;
    padding: 40px 30px;
    box-sizing: border-box;
    border-radius: 30px;
  }

  .slider__big-btn {
    width: 20px;
    font-size: 40px;
    padding: 20px;
  }
}