.event-page {
  display: grid;
  grid-template-areas: 'back about'
    'title about'
    'list about';
  grid-template-columns: auto 60%;
  grid-template-rows: 80px 220px 450px;
  gap: 20px;
}

.event-page__back-btn {
  margin: 0;
  padding: 0;
  grid-area: back;
  transition: all .3s linear;
  background: none;
  border: none;
}

.event-page__back-btn:hover {
  cursor: pointer;
  transform: scaleX(1.01) translateX(-5px);
}

.event-page__back-btn:hover .event-page__back-btn-text {
  color: var(--technolab-blue);
}

.event-page__title-tile {
  grid-area: title;
  align-items: flex-end;
  justify-content: space-between;
  text-transform: uppercase;
  color: var(--technolab-blue);
  height: 100%;
  background: #f1f0f0;
}

.event-page__title {
  margin: 0;
  padding: 0;
  color: var(--technolab-blue);
  font-size: 28px;
}

.event-page__logo {
  width: 60%;
  max-width: 250px;
  align-self: flex-start;
}

.event-page__list {
  grid-area: list;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-page__link {
  height: 100px;
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 40% auto;
}

.event-page__link_type_highlighted {
  background-color: var(--technolab-blue);
  color: #f1f0f0;
  transition: all .4s linear;
  background-image: url(../../images/events/bridge-registration.png);
}

.event-page__link_type_field {
  background-image: url(../../images/events/bridge-field.png);
}

.event-page__link_type_guide {
  background-image: url(../../images/events/bridge-guide.png);
}

.event-page__link_type_products {
  background-image: url(../../images/events/bridge-products.png);
}

.event-page__link-text {
  width: 50%;
  text-align: left;
}

.event-page__about {
  grid-area: about;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.event-page__scores {
  align-self: center;
}

.event-page__table {
  max-width: 500px;
  width: 100%;
  align-self: center;
  margin-top: 15px;
}

@media (max-width: 1250px) {

  .event-page__title {
    font-size: 24px;
  }

}

@media (max-width: 1200px) {

  .event-page {
    display: grid;
    grid-template-areas: 'back list'
      'title list'
      'about about';
    grid-template-rows: 80px 350px 500px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

}

@media (max-width: 750px) {

  .event-page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .event-page__list li {
    margin-top: 15px;
  }

  .event-page__list li:first-of-type {
    margin: 0;
  }

  .event-page__about {
    max-height: 500px;
  }

  .event-page__title {
    font-size: 18px;
  }

  .event-page__back-btn {
    height: 50px;
  }

  .event-page__link {
    background-size: auto 85%;
  }

}

@media (max-width: 460px) {

  .event-page__link {
    background-image: none;
  }

  .event-page__link-text {
    width: 100%;
  }

}