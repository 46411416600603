.card-with-menu {
  position: relative;
}

.card-with-menu__navbar {
  -ms-grid-column-align: center;
  justify-self: center;
  grid-area: navbar;
  display: flex;
  width: 60%;
  justify-content: space-between;
  padding: 0 35px 10px 35px;
}

/* КНОПКА НАЗАД КАРТОЧКИ */

.card-with-menu__link-back {
  z-index: 6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 1;
  width: 110px;
  height: 90px;
  position: absolute;
  top: 35px;
  left: 0;
}

.card-with-menu__link-back:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.card-with-menu__back-btn {
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  height: 90px;
  width: 100px;
  overflow: hidden;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  background-image: url('../../images/cards/default-btn.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 140px 88px;
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, .2);
  display: grid;
  align-content: flex-end;
}

.card-with-menu__back-btn-text {
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  opacity: 1;
  color: #fff;
  margin: 0;
  padding: 0 0 14px 12px;
  box-sizing: border-box;
}

.card-with-menu__mini-back-btn {
  background-color: rgba(255, 255, 255, 0);
  background-image: url('../../images/cards/back-btn.png');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 40px auto;
  border: none;
  height: 90px;
  width: 150px;
  justify-self: end;
  cursor: pointer;
  position: relative;
  left: -48px;
  top: 4px;
  z-index: 6;
  transition: transform .3s ease-in-out;
  padding: 0;
}

.card-with-menu__mini-back-btn:hover {
  transform: scale(1.05, 1.05);
}

/* КНОПКА НАВИГАЦИИ КАРТОЧКИ */

.card-with-menu__link-btn {
  color: #E9E9E9;
  position: relative;
  opacity: .7;
  transition: all .3s ease-in-out;
}

.card-with-menu__link-btn:hover {
  position: relative;
  opacity: 1;
}

.card-with-menu__link-btn::after {
  content: '';
  background-image: url('../../images/cards/default-btn-inactive.png');
  background-position: center 15px;
  width: 130px;
  height: 90px;
  margin: -5px 0 0 0;
  padding: 0 0 15px 0;
  position: absolute;
}

.card-with-menu__link-btn_rotate {
  background-position: center bottom;
  margin: 0 -35px 0 0;
  padding: 0;
  transform: rotate(180deg);
}

.card-with-menu__rotate-btn-text {
  transform: rotate(180deg);
  margin: 0;
  padding-top: 8px;
}

.card-with-menu__link-btn_active {
  opacity: 1;
  color: #fff;
  scale: 1.05;
}

.card-with-menu__link-btn_active::after {
  display: none;
}

/* ФОНОВАЯ КАРТИНКА КАРТОЧКИ */

.card-with-menu__pic {
  grid-area: pic;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  -ms-grid-column-align: start;
  justify-self: start;
  margin: -25px 0 0 -40px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.card-with-menu__pic_level_research {
  background-image: url('../../images/cards/research-pic.png');
}

.card-with-menu__pic_level_professional {
  background-image: url('../../images/cards/professional-pic.png');
}

.card-with-menu__pic_level_preliminary {
  background-image: url('../../images/cards/preliminary-pic.png');
}

.card-with-menu__pic_level_machines {
  background-image: url('../../images/cards/machines-pic.png');
}

.card-with-menu__pic_level_elementary {
  background-image: url('../../images/cards/elementary-pic.png');
}

.card-with-menu__pic_level_basic {
  background-image: url('../../images/cards/basic-pic.png');
}

.card-with-menu__pic_level_aero {
  background-image: url('../../images/cards/aero-pic.png');
}

@media (max-width: 1000px) {

  .card-with-menu__mini-back-btn {
    display: none;
  }
}

@media (max-width: 900px) {

  .card-with-menu {
    margin: 0 auto;
  }
}

@media (max-width: 500px) {

  .card-with-menu__rotate-text {
    padding-top: 3px;
  }

  .card-with-menu__pic {
    display: none;
  }

  .card-with-menu__link-back {
    top: 32px;
    width: 85px;
    height: 76px;
  }

  .card-with-menu__back-btn {
    width: 85px;
    height: 76px;
    background-size: 120px 75px;
  }

  .card-with-menu__back-btn-text {
    font-size: 13px;
  }

  .card-with-menu__link-btn::after {
    background-position: center 0;
    width: 110px;
    height: 70px;
  }
}